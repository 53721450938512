<template>
    <div class="form_container">
        <div class="row">
            <div class="col-lg-4 position-relative d-none d-lg-block">
                <div class="image-bg" />
            </div>
            <div class="col-lg-8 col-md-12">
                <Form ref="formCallback" :validation-schema="schema" class="d-flex flex-column body-form" @submit="sendForm">
                    <div class="col-12 d-flex flex-column form-block">
                        <h2 class="modal-title">{{ consultFormData.title }}</h2>
                        <h3 class="modal-sub-titile">{{ consultFormData.subtitle }}</h3>
                        <div class="form-control-item">
                            <div v-for="(fieldData, index) in filteredFieldForm" :key="index" class="form-control-callback">
                                <Field v-slot="{ errorMessage }" v-model="modelForm[fieldData[0]]" :label="fieldData[1]" :name="fieldData[0]">
                                    <phone-input v-if="fieldData[0] === 'tel_number'" v-model="modelForm[fieldData[0]]" :error="errorMessage" :label="fieldData[1]" :name="fieldData[0]" />
                                    <new-default-input v-else v-model="modelForm[fieldData[0]]" :label="fieldData[1]" :error="errorMessage" :name="fieldData[0]" />
                                </Field>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <CheckboxInput v-model="modelForm.per_data" :label="informationField.per_data" />
                    </div>
                    <div class="col-12">
                        <CheckboxInput v-model="modelForm.mailing" :label="informationField.mailing" />
                    </div>
                    <div class="col-12">
                        <default-button size="large" class="consult-button" :disabled="modelForm.per_data === false">{{ consultFormData.buttons_text.content }}</default-button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import NewDefaultInput from '~/components/Input/NewDefaultInput.vue';
import CheckboxInput from '~/components/Input/CheckboxInput.vue';
import DefaultButton from '~/components/Button/DefaultButton.vue';
import { useContentPages } from '~/stores/homeStores';
import PhoneInput from '~/components/Input/PhoneInput.vue';
import { Form, Field } from 'vee-validate';
import { sendContact } from '~/api/sendContactForm/sendContact';
import * as yup from 'yup';

const contentPagesStore = useContentPages();
const consultFormData = computed(() => contentPagesStore.getContactFormData);
const informationField = consultFormData.value.fields;
const fieldsForm = computed(() => Object.entries(consultFormData.value.fields));
const filteredFieldForm = computed(() => fieldsForm.value.filter(value => value[0] !== 'mailing' && value[0] !== 'per_data')); // unset  mailing and news
const modelObject = Object.fromEntries(Object.keys(consultFormData.value.fields).map(key => [key, '']));
const policyAndSpam = { mailing: false, per_data: true };

const emit = defineEmits<{ (e: 'update:formSuccess', value: boolean): void }>();
const formSend = ref(false);
const schema = yup.object().shape({
    name: yup.string().required().min(1),
    tel_number: yup.string().required().min(1),
});

const modelForm = ref({ ...modelObject, ...policyAndSpam });

const sendForm = async (data, actions) => {
    const sendData = { ...data, email_to: consultFormData.value.email };
    const response = await sendContact(sendData);
    if (response.status !== 200) {
        emit('update:formSuccess', false);
        return false;
    }
    formSend.value = true;
    // GTM for backend trigger
    eval(consultFormData.value.event);
    actions.resetForm();
    emit('update:formSuccess', true);
};
</script>

<style lang="scss" scoped>
.image-bg {
    width: 30.5625em;
    height: 44.875em;
    position: absolute;
    left: -10.0625em;
    bottom: -3.9rem;
    background: url('/images/man_1.png') no-repeat;
}
.body-form {
    gap: 1.75rem;
}
.form-block {
    gap: 2.5rem;
    font-family: Roboto;
    flex-wrap: wrap;
}
.consult-button {
    font-weight: 700;
    font-size: 20px !important;
    line-height: 24px;
    padding: 20px 36px !important;
}
.form-control-item {
    display: flex;
    flex-direction: row;
    gap: 1.5rem 1.5rem;
    flex-wrap: wrap;
}
.form-control-callback {
    max-width: 23.21rem;
    width: 46%;
}
.modal-title {
    color: var(--main-black-2-b-2-b-2-b, #2b2b2b);
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.75rem;
}
.modal-sub-titile {
    color: var(--main-black-2-b-2-b-2-b, #2b2b2b);
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.25rem;
}

@media (max-width: 480px) {
    .logo-pec {
        display: none;
    }
    .modal-title {
        color: var(--main-black-2-b-2-b-2-b, #2b2b2b);
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2rem;
    }
    .form-block {
        gap: 1rem;
    }
    .form-control-item {
        display: flex;
        flex-direction: row;
        gap: 0.75rem;
        flex-wrap: wrap;
    }
    .form-control-callback {
        max-width: unset;
        width: 100%;
    }
    .form-control-callback:last-child:nth-child(odd) {
        max-width: unset;
        width: 100%;
    }
    .consult-button {
        width: 100%;
    }
}
</style>
